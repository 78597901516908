import styles from "./AccountHud.module.css";
import AccountButton from "./AccountButton/AccountButton";
import FriendsButton from "./FriendsButton/FriendsButton";
import SettingsButton from "./SettingsButton/SettingsButton";

function AccountHud(){
    return <div className={styles.container}>
        <AccountButton/>
        <FriendsButton />
        <SettingsButton />
    </div>
}

export default AccountHud