import styles from "./SignUpUI.module.css";
import LabelAndDivider from "../LabelAndDivider/LabelAndDivider";
import { RiArrowGoBackFill } from "react-icons/ri";
import { IconContext } from "react-icons";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../redux/actions/moba-action";
import { useState } from "react";
import axios from 'axios';
import PAGES from "../../../constants/moba";
import MoonLoader from "react-spinners/MoonLoader";
import { updateAccount } from "../../../redux/actions/account-actions";
import { updatePlayerName } from "../../../redux/actions/userData-actions";
const defaultUserError = "Invalid Username";
const defaultPasswordError = "Invalid Password";

function SignUpUI() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const [usernameError, setUsernameError] = useState(defaultUserError);
  const [passwordError, setPasswordError] = useState(defaultPasswordError);
  const [successResponse, setSuccessResponse] = useState(false);

  const dispatch = useDispatch();

  const onBackButtonClick = (e) => {
    e.preventDefault();
    dispatch(setActivePage(PAGES.MENU_SCREEN_MAIN));
  };
  const onPlayerNameChange = (e) => {
    resetUsernameError();
    setUsername(e.target.value);
  };
  const onPasswordChange = (e) => {
    resetPasswordError();
    setPassword(e.target.value);
  };
  const onRePasswordChange = (e) => {
    resetPasswordError();
    setRePassword(e.target.value);
  };
  function resetPasswordError() {
    if (password != defaultPasswordError) setPasswordError(defaultPasswordError);
  }
  function resetUsernameError() {
    if (username != defaultUserError) setUsernameError(defaultUserError);
  }
  const onRegisterClick = (e) =>{
    //Todo: Make an environment variable so that 
    // Development = localhost:5000/user/signup
    // Production = https://project-moba/user/signup
    console.log(`Signup Post Attempted with ${username} ${password}`);
    if (!bothPasswordsMatch()) {
      setPasswordError("Passwords do not match");
    }
    else {
      axios
        .post("https://project-moba.com/user/signup", {
          username: username,
          password: password,
        })
        .then(function (response) {
          console.log("Signup Response")
          console.log(response);
          dispatch(updateAccount(response.data));
          dispatch(updatePlayerName(response.data.username ? response.data.username : "AnDuhRoo"));
          setSuccessResponse(true);
          setTimeout(() => {
            dispatch(setActivePage(PAGES.MENU_SCREEN_CHAMPIONS));
          }, 900);
        })
        .catch(function (error) {
          if (
            error.response &&
            error.response.data &&
            (error.response.data.username || error.response.data.password)
          ) {
            if (error.response.data.username) {
              setUsernameError(error.response.data.username);
            }
            if (error.response.data.password) {
              setPasswordError(error.response.data.password);
            }
            return;
          }
          setUsernameError("An error occurred");
        });
    }
    
  }
  function bothPasswordsMatch(){
    return password == rePassword;
  }

  return (
    <div className={styles.container}>
      <div className={styles.menuUIFlexBox}>
        <LabelAndDivider label={"Register"} style={{ marginBottom: "16px" }} />
        {usernameError !== defaultUserError ? (
          <p className={styles.errorLabel}>{usernameError}</p>
        ) : null}
        {successResponse ? (
          <p className={styles.successLabel}>Success</p>
        ) : null}
        <input
          className={styles.guestNameInput}
          type="text"
          placeholder="Enter your name"
          onChange={onPlayerNameChange}
        />
        {passwordError !== defaultPasswordError ? (
          <p className={styles.errorLabel}>{passwordError}</p>
        ) : null}
        <input
          className={styles.guestNameInput}
          type="password"
          placeholder="Enter your Password"
          onChange={onPasswordChange}
        />
        <input
          className={styles.guestNameInput}
          type="password"
          placeholder="Re-enter your Password"
          onChange={onRePasswordChange}
        />

        {!successResponse ? (
          <button className={styles.registerButton} onClick={onRegisterClick}>
            Sign Up
          </button>
        ) : (
          <MoonLoader
            size={50}
            cssOverride={{ marginBottom: "32px" }}
            color={"rgba(0,10,20,1.0)"}
            loading={true}
            speedMultiplier={0.5}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
        {!successResponse ? (
          <button className={styles.backButton} onClick={onBackButtonClick}>
            <IconContext.Provider
              value={{
                color: "rgba(0,0,0,0.7)",
                size: "24px",
                style: { margin: "auto" },
              }}
            >
              <RiArrowGoBackFill />
            </IconContext.Provider>
            <div>Back</div>
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default SignUpUI;
