import styles from "./MenuiUI.module.css"
import LabelAndDivider from "../LabelAndDivider/LabelAndDivider";

import PAGES from "../../../constants/moba";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../redux/actions/moba-action";
import { updatePlayerName } from "../../../redux/actions/userData-actions";
import getRandom8DigitString from "../../../utils/generateRandom8Digits.js";

import MoonLoader from "react-spinners/MoonLoader.js";
import axios from "axios";
import { updateAccount } from "../../../redux/actions/account-actions.js";


const defaultLoginError = "Error";

function MenuUI() {
    const dispatch = useDispatch();
    const [guestNameInput, setGuestName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [loginError, setLoginError] = useState(defaultLoginError);

    function resetErrorLabel() {
      if (loginError != defaultLoginError) {
        setLoginError(defaultLoginError);
      }
    }
    function onGuestNameChange(event) {
      resetErrorLabel();
      setGuestName(event.target.value);
    }
    const onGuestPlayButton = (e) => {
      e.preventDefault();
      axios
        .post("https://project-moba.com/user/guestplay", {
          guestName:
            guestNameInput === ""
              ? `Guest_${getRandom8DigitString()}`
              : guestNameInput,
        })
        .then((response) => {
          console.log(response);
          dispatch(updateAccount(response.data));
          dispatch(updatePlayerName(response.data.guestName ? response.data.guestName : "AnDuhRoo"));
          setIsLoading(true);
          setTimeout(()=>{
            setIsLoading(false);
            dispatch(setActivePage(PAGES.MENU_SCREEN_CHAMPIONS))},900)
            
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.data?.failMessage) {
            return setLoginError(error.response.data.failMessage);
          }
          setLoginError("Guest Join Error");
        });
      
    };
    const onLoginButtonClick = (e) => {
      e.preventDefault();
      dispatch(setActivePage(PAGES.MENU_SCREEN_LOGIN));
    };
    const onSignupButtonClick = (e) => {
      e.preventDefault();
      dispatch(setActivePage(PAGES.MENU_SCREEN_SIGNUP));
    };

    
    return (
      <div className={styles.container}>
        <div className={styles.menuUIFlexBox}>
          <LabelAndDivider label={"Welcome"} style={{ marginBottom: "16px" }} />
          {loginError !== defaultLoginError ? (
            <p className={styles.errorLabel}>{loginError}</p>
          ) : null}
          <input
            className={styles.guestNameInput}
            onChange={onGuestNameChange}
            type="text"
            placeholder="Enter your name"
          />
          {!isLoading ?
          <button
            className={styles.guestPlayButton}
            onClick={onGuestPlayButton}
          >
            Guest Play
          </button>
          :
          <MoonLoader
            size={100}
            cssOverride={{ marginBottom: "32px" }}
            color={"rgba(0,10,20,1.0)"}
            loading={true}
            speedMultiplier={1.0}
            aria-label="Loading Spinner"
            data-testid="loader"
          />}
          {!isLoading ? 
          <div className={styles.authButtonsFlex}>
            <button className={styles.authButton} onClick={onLoginButtonClick}>
              Login
            </button>
            <button className={styles.authButton} onClick={onSignupButtonClick}>
              Signup
            </button>
          </div>
          : null}
        </div>
      </div>
    );
}

export default MenuUI
