import styles from "./ChampionSelectUI.module.css";
import { RiArrowGoBackFill } from "react-icons/ri";
import { IconContext } from "react-icons";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../../../redux/actions/moba-action";
import { setSelectedChampion } from "../../../redux/actions/championSelect-action";
import { joinGame } from "../../../utils/networking";
import AccountHud from "./AccountHud/AccountHud.js";
import getRandom8DigitString from "../../../utils/generateRandom8Digits.js";
import Label from "./Label/Label";
import PAGES from "../../../constants/moba";
import ChampionNames from "../../../constants/ChampionNames";
import archer from "../../../assets/champions/cartoony/archer.png";
import lancer from "../../../assets/champions/cartoony/lancer.png";
import cleric from "../../../assets/champions/cartoony/cleric.png";
import wizard from "../../../assets/champions/cartoony/wizard.png";
import swordsman from "../../../assets/champions/cartoony/swordsman.png";


const championNameMap = {
  [ChampionNames.LUCCA_BOAR]: "Lucca",
  [ChampionNames.REMI]: "Remi",
  [ChampionNames.JIRQO]: "Jirqo",
};

function ChampionSelectUI() {
    const dispatch = useDispatch();
      const selectedChampion = useSelector((state)=>state.championSelect.selectedChampion);
    const playerName = useSelector((state) => state.userData.playerName);



    const onBackButtonClick = (e) => {
      e.preventDefault();
      dispatch(setActivePage(PAGES.MENU_SCREEN_MAIN));
    };
    function onChampionSelect(name) {
      dispatch(setSelectedChampion(name));
    }
    function onSelectChampionClick() {
      if (selectedChampion != ChampionNames.NO_CHAMPION) {
        dispatch(setActivePage(PAGES.GAME_SCREEN));
        joinGame(playerName, selectedChampion);
      }
    }
    function generateHoverClass(champion){
      // alert(selectedChampion);
      if (selectedChampion == ChampionNames.NO_CHAMPION) return styles.unSelectedChampionHover;
      else if (selectedChampion == champion) return styles.selectedChampionGlow;
      return null;
    }


    
    return (
      <div className={styles.container}>
        <AccountHud> </AccountHud>
        <Label></Label>
        <div className={styles.flex}>
          <div className={styles.championSelectContainer}>
            {selectedChampion == ChampionNames.NO_CHAMPION ? (<div className={styles.pickAChampionText}>
              Pick A<br/>Champion
            </div>) : (<div className={styles.championText}>{championNameMap[selectedChampion]}</div>)}
            
            <img
              src={archer}
              onClick={() => onChampionSelect(ChampionNames.JIRQO)}
              className={`${styles.championAvatarButton} ${
                styles.avatar1
              } ${generateHoverClass(ChampionNames.JIRQO)}`}
            />
            <img
              src={lancer}
              onClick={() => onChampionSelect(ChampionNames.LUCCA_BOAR)}
              className={`${styles.championAvatarButton} ${
                styles.avatar2
              } ${generateHoverClass(ChampionNames.LUCCA_BOAR)}`}
            />
            <img
              src={swordsman}
              onClick={() => onChampionSelect(ChampionNames.REMI)}
              className={`${styles.championAvatarButton} ${
                styles.avatar3
              } ${generateHoverClass(ChampionNames.REMI)}`}
            />
            <div
              className={`${styles.championAvatarButton} ${
                styles.avatar4
              } ${generateHoverClass(ChampionNames.FUTURE_CHAMPION_1)}`}
            >
              <div className={styles.lockOverlay}></div>
              <img
                src={wizard}
                onClick={() => null}
                className={styles.championImage}
              />
            </div>
            <div
              className={`${styles.championAvatarButton} ${
                styles.avatar5
              } ${generateHoverClass(ChampionNames.FUTURE_CHAMPION_2)}`}
            >
              <div className={styles.lockOverlay}></div>
              <img
                src={cleric}
                onClick={() => null}
                className={styles.championImage}
              />
            </div>
          </div>

          <button
            className={styles.championSelectButton}
            onClick={onSelectChampionClick}
          >
            Join Game
          </button>
          <button className={styles.leaderboardButton}>Leaderboards</button>
          <button className={styles.backButton} onClick={onBackButtonClick}>
            <IconContext.Provider
              value={{
                color: "rgba(0,0,0,0.7)",
                size: "24px",
                style: { margin: "auto" },
              }}
            >
              <RiArrowGoBackFill />
            </IconContext.Provider>
            <span>Back</span>
          </button>
        </div>
      </div>
    );
}

export default ChampionSelectUI;