import styles from "./LoginUI.module.css"
import LabelAndDivider from "../LabelAndDivider/LabelAndDivider";
import { RiArrowGoBackFill } from "react-icons/ri";
import { IconContext } from "react-icons";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../redux/actions/moba-action";
import { updateAccount } from "../../../redux/actions/account-actions";
import { updatePlayerName } from "../../../redux/actions/userData-actions";
import { useState } from "react";
import axios from 'axios';
import PAGES from "../../../constants/moba";
import MoonLoader from "react-spinners/MoonLoader";


const defaultLoginError = "Error"

function LoginUI() {
    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState(defaultLoginError);
    const [successResponse, setSuccessResponse] = useState(false);

    const onPlayerNameChange = (e) => {
      resetErrorLabel();
      setUsername(e.target.value);
    };
    const onPasswordChange = (e) => {
      resetErrorLabel();
      setPassword(e.target.value);
    };
    function resetErrorLabel(){
      if (loginError != defaultLoginError){
        setLoginError(defaultLoginError);
      }
    }

    const onBackButtonClick = (e) => {
      e.preventDefault();
      dispatch(setActivePage(PAGES.MENU_SCREEN_MAIN));
    };
    const onLoginButtonClick = (e) => {
      axios
        .post("https://project-moba.com/user/authenticate", {
          username: username,
          password: password,
        })
        .then(function (response) {
          setSuccessResponse(true);
          console.log("Response Data");
          console.log(response)
          dispatch(updateAccount(response.data));
          dispatch(updatePlayerName(response.data.username ? response.data.username : "AnDuhRoo"));
          setTimeout(() => {
            dispatch(setActivePage(PAGES.MENU_SCREEN_CHAMPIONS));
          }, 9000);
        })
        .catch(function (error) {
          if (error?.response?.data?.username){
            return setLoginError(error.response.data.errorMessage);
          }
          setLoginError("Login error");
        });
    }
    
    return (
      <div className={styles.container}>
        <div className={styles.menuUIFlexBox}>
          <LabelAndDivider label={"Login"} style={{ marginBottom: "16px" }} />
          {loginError !== defaultLoginError ? (
            <p className={styles.errorLabel}>{loginError}</p>
          ) : null}
          {successResponse ? (
            <p className={styles.successLabel}>Success</p>
          ) : null}
          <input
            className={styles.guestNameInput}
            type="text"
            placeholder="Enter your name"
            onChange={onPlayerNameChange}
          />
          <input
            className={styles.guestNameInput}
            type="password"
            placeholder="Enter your Password"
            onChange={onPasswordChange}
          />
          {!successResponse ? (
            <button className={styles.loginButton} onClick={onLoginButtonClick}>
              Log In
            </button>
          ) : (
            <MoonLoader
              size={50}
              cssOverride={{ marginBottom: "32px" }}
              color={"rgba(0,10,20,1.0)"}
              loading={true}
              speedMultiplier={0.5}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
          {!successResponse ? (
            <button className={styles.backButton} onClick={onBackButtonClick}>
              <IconContext.Provider
                value={{
                  color: "rgba(0,0,0,0.7)",
                  size: "24px",
                  style: { margin: "auto" },
                }}
              >
                <RiArrowGoBackFill />
              </IconContext.Provider>
              <div>Back</div>
            </button>
          ) : null}
        </div>
      </div>
    );
}

export default LoginUI;
