import styles from "./FriendsButton.module.css";
function FriendsButton(){
    return (
      <div className={styles.container}>
        <div className={styles.flex}>
          <svg
            width="36"
            height="40"
            viewBox="0 0 36 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_755_50)">
              <path
                d="M11.1791 20.1197C14.5987 20.1197 17.3664 15.6866 17.3664 10.2093C17.3664 4.73198 14.5987 0.298828 11.1791 0.298828C7.7595 0.298828 4.99178 4.73198 4.99178 10.2093C4.99178 15.6866 7.7595 20.1197 11.1791 20.1197ZM15.4218 22.9513H14.9633C13.8142 23.8361 12.5381 24.3671 11.1791 24.3671C9.8201 24.3671 8.54949 23.8361 7.39489 22.9513H6.93636C3.42285 22.9513 0.572266 27.5172 0.572266 33.1449V35.6933C0.572266 38.0382 1.76001 39.9406 3.22397 39.9406H19.1342C20.5982 39.9406 21.7859 38.0382 21.7859 35.6933V33.1449C21.7859 27.5172 18.9353 22.9513 15.4218 22.9513ZM27.0893 20.1197C30.0173 20.1197 32.3928 16.3148 32.3928 11.6251C32.3928 6.93529 30.0173 3.13038 27.0893 3.13038C24.1614 3.13038 21.7859 6.93529 21.7859 11.6251C21.7859 16.3148 24.1614 20.1197 27.0893 20.1197ZM29.741 22.9513H29.5311C28.7632 23.376 27.9511 23.6592 27.0893 23.6592C26.2275 23.6592 25.4154 23.376 24.6476 22.9513H24.4376C23.3107 22.9513 22.2721 23.4733 21.3605 24.314C22.7085 26.6412 23.5537 29.7293 23.5537 33.1449V36.5428C23.5537 36.7374 23.5261 36.9232 23.5206 37.1091H33.2767C34.7406 37.1091 35.9284 35.2066 35.9284 32.8617C35.9284 27.3844 33.1606 22.9513 29.741 22.9513Z"
                fill="#ACACAC"
              />
            </g>
            <defs>
              <clipPath id="clip0_755_50">
                <rect
                  width="35.3561"
                  height="39.6418"
                  fill="white"
                  transform="translate(0.572266 0.298828)"
                />
              </clipPath>
            </defs>
          </svg>
          <p className={styles.secondFlexItem}>FRIENDS</p>
        </div>
      </div>
    );
}

export default FriendsButton;