import styles from "./NewMenu.module.css";
import { useEffect, useRef } from "react";
import PAGES from "../../constants/moba";
import { useSelector } from "react-redux";
import MenuUI from "./MenuUI/MenuUI.js";
import LoginUI from "./LoginUI/LoginUI.js";
import SignUpUI from "./SignUpUI/SignUpUI.js";
import ChampionSelectUI from "./ChampionSelectUI/ChampionSelectUI.js";
import championSelectImage from "../../assets/mmoBackground.png"
import loginBackgroundImage from "../../assets/loginBackground.png"


export default function NewMenu() {
  let menuRef = useRef(null);
  const activePage = useSelector((state) => state.moba.activePage);

  function parallax(e) {
    if (menuRef && menuRef.current) {
      let _w = window.innerWidth / 2;
      let _h = window.innerHeight / 2;
      let _mouseX = e.clientX;
      let _mouseY = e.clientY;
      let _depth1 = `${50 + (_mouseX - _w) * 0.01}% ${
        50 + (_mouseY - _h) * 0.005
      }%`;
      let _depth2 = `${50 + (_mouseX - _w) * 0.01}% ${
        50 + (_mouseY - _h) * 0.01
      }%`;
      let _depth3 = `${50 + (_mouseX - _w) * 0.01}% ${
        50 + (_mouseY - _h) * 0.03
      }%`;
      let x = `${_depth3}, ${_depth2}, ${_depth1}`;
      menuRef.current.style.backgroundPosition = x;
    }
  }

  useEffect(() => {
    if (menuRef && menuRef.current) {
      document.addEventListener("mousemove", parallax);
    }
  }, []);
  useEffect(()=>{
    if (activePage==PAGES.MENU_SCREEN_CHAMPIONS){
      menuRef.current.style.backgroundImage = `url(${championSelectImage})`;
    }
    else {
      menuRef.current.style.backgroundImage = `url(${loginBackgroundImage})`;
    }

  }, [activePage]);
  useEffect(()=>{
    if (menuRef && menuRef.current) {
      // menuRef.current.style.backgroundImage = `url(${championSelectImage})`;
    }

  });
  
  

  return (
    <div ref={menuRef} className={styles.container}>
      {activePage == PAGES.MENU_SCREEN_MAIN && <MenuUI></MenuUI>}
      {activePage == PAGES.MENU_SCREEN_LOGIN && <LoginUI></LoginUI>}
      {activePage == PAGES.MENU_SCREEN_SIGNUP && <SignUpUI></SignUpUI>}
      {activePage == PAGES.MENU_SCREEN_CHAMPIONS && <ChampionSelectUI></ChampionSelectUI>}
    </div>
  );
}
