import { UPDATE_ACCOUNT } from "../constants/account-types";

const initialState = {
  account: {},
};

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ACCOUNT:
      return {
        ...state,
        accountInfo: action.accountInfo,
      };
    default:
      return state;
  }
}
