import { useSelector } from "react-redux";
import MenuScreen from "./menu/MenuScreen";
import LoadingScreen from "./loading/LoadingScreen";
import GameScreen from "./game/GameScreen";
import PAGES from "../constants/moba";
import { connect, onGameOver } from "../utils/networking";
import { useEffect, useState } from "react";



import allChampions from "../game/champions/champions";
import ChampionNames from "../constants/ChampionNames";
import titlePicture from "../assets/titlePicture.png";
import menuBackground from "../assets/menuBackground.png";

const assetNames = [
  allChampions[ChampionNames.JIRQO].image,
  allChampions[ChampionNames.REMI].image,
  allChampions[ChampionNames.LUCCA_BOAR].image,
  allChampions[ChampionNames.FUTURE_CHAMPION_1].image,
  allChampions[ChampionNames.FUTURE_CHAMPION_2].image,
  allChampions[ChampionNames.FUTURE_CHAMPION_3].image,
  titlePicture,
  menuBackground,
];

function downloadAsset(imagePath) {
  let downloadPromise = new Promise((resolve) => {
    let newImage = new Image();
    newImage.onload = () => {
      resolve();
    };
    newImage.src = imagePath;
  });
  return downloadPromise;
}
export default function Moba() {
  // Moba.js will be a centralized management file 
  // Active Page (SPA). 
  const activePage = useSelector((state)=>state.moba.activePage);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    function preloadAssets() {
      const downloadAllAssets = ()=>Promise.all(assetNames.map(downloadAsset));
      Promise.all([connect(onGameOver), downloadAllAssets()]).then(()=>{
        setIsLoading(false);
      }).catch((err)=>{
        console.log(`ERROR: ${err}`)
        setIsLoading(false);
      })
    }
    preloadAssets();
  }, []);

  return isLoading ? (
    <LoadingScreen></LoadingScreen>
  ) : (
    <>
      {activePage == PAGES.MENU_SCREEN_MAIN && <MenuScreen />}
      {activePage == PAGES.MENU_SCREEN_LOGIN && <MenuScreen />}
      {activePage == PAGES.MENU_SCREEN_SIGNUP && <MenuScreen />}
      {activePage == PAGES.MENU_SCREEN_CHAMPIONS && <MenuScreen />}
      {activePage == PAGES.GAME_SCREEN && <GameScreen />}
    </>
  );
}
