import styles from "./LabelAndDivider.module.css"


function LabelAndDivider({label}) {

    
    return (
      <div className={styles.container}>
        <div className={styles.labelText}>{label}</div>
      </div>
    );
}

export default LabelAndDivider;